<template>
  <a-spin :spinning="spinning">

  <div class="">
    <div class="tk_table">
      <!-- <react-table
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :scroll="{ x: 1400 }"
        :columns="columns"
        :is-full="true"
        :current-page="pageNumber"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
      ref="rt"
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actions-type="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="false"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
        <!-- <a-form-model
          layout="inline"
          :model="searchFormInline"
          @submit="getData"
          @submit.native.prevent
        > -->
        <!-- <a-form-model-item label=""> -->
        <a-row :gutter="8">
          <a-col :span="4">
            <a-input
              v-model="searchFormInline.projectName"
              placeholder="请选择项目"
              @click="selectProjectModal"
            >
            </a-input>
          </a-col>
          <a-col :span="4">
            <!-- </a-form-model-item> -->
            <!-- <a-form-model-item label=""> -->
            <a-select
              placeholder="请选择任务"
              show-search
              style="width: 100%"
              v-model="searchFormInline.taskId"
              allowClear
            >
              <a-select-option
                :value="it.id"
                v-for="(it, i) in taskList"
                :key="i"
              >
                {{ it.name }}
              </a-select-option>
            </a-select>
          </a-col>
          <!-- </a-form-model-item> -->
          <!-- <a-form-model-item label=""> -->
          <a-col :span="4">
            <a-input
              v-model.trim="searchFormInline.filterText"
              placeholder="身份证/手机号/姓名"
              allowClear
            >
              <a-icon
                slot="prefix"
                type="key"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-col>
          <!-- </a-form-model-item> -->
          <!-- <a-form-model-item label=""> -->
          <a-col :span="4">
            <a-select
              style="width: 100%"
              placeholder="请选择审核状态"
              v-model="searchFormInline.taskStatus"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in taskStatusList"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <!-- </a-form-model-item> -->
          <!-- <a-form-model-item> -->
          <!-- <a-button type="primary" html-type="submit"> -->
          <a-col :span="4">
            <a-button type="primary" @click="getData">
              <!-- <a-icon type="search" /> -->
               <span>搜索</span>
            </a-button>
                       <a-button @click="clearFilterAndRefresh">
                    {{ l("重置") }}
                  </a-button>
          </a-col>
          <!-- </a-form-model-item> -->
          <!-- </a-form-model> -->
          <!-- <a-space> -->
          <a-col :span="4" style="text-align: right">
            <a-button-group>
              <a-button
                type=""
                @click="exportToExcel()"
                :disabled="
                  !isGranted('Pages.LGModul.Signup.Export')
                "
              >
                <a-icon type="file-excel" />
                {{ l("导出") }}
              </a-button>
              <a-dropdown>
                <a-menu slot="overlay">
                  <!-- v-if="isGranted('Pages.LGModul.ProjectPersonel.Import') && selectedRowKeys.length > 0" -->
                  <a-menu-item key="1">
                    <a-button
                      type="primary"
                      @click="batchPass()"
                      :disabled="
                        !isGranted('Pages.LGModule.Signup.BatchPass') ||
                        !selectedRows.length
                      "
                    >
                      <a-icon type="check-square" />
                      {{ l("批量通过") }}
                    </a-button>
                  </a-menu-item>
                  <a-menu-item key="2">
                    <a-button
                      type="danger"
                      :disabled="
                        !isGranted('Pages.LGModule.Signup.BatchReject') ||
                        !selectedRows.length
                      "
                      @click="batchRefuse()"
                    >
                      <a-icon type="close-square" />
                      {{ l("批量拒绝") }}
                    </a-button>
                  </a-menu-item>
                </a-menu>
                <a-button> 更多 <a-icon type="down" /> </a-button>
              </a-dropdown>
            </a-button-group>
          </a-col>
          <!-- </a-space> -->
        </a-row>
      </ele-table>
    </div>
    <a-modal
      centered
      :title="inputModalParams.title"
      :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading"
      :cancelText="inputModalParams.cancelText"
      :okText="inputModalParams.okText"
      :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose"
      :width="inputModalParams.width"
      @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel"
    >
      <div>
        <a-form :form="form" v-bind="formItemLayout">
          <a-form-item label="通过审核" required>
            <a-switch
              checked-children="是"
              un-checked-children="否"
              v-decorator="[
                'isPass',
                { valuePropName: 'checked', initialValue: true },
              ]"
              @change="handleChange"
            />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
// import TaskForm from "./action/addorupdate.vue";
// import DetailForm from "./action/details";
import axios from "axios";
import EleTable from "@/components/ele-table";
import moment from "moment";
import { fileDownloadService } from "@/shared/utils";
import SelectPageList from "@/components/linggong/select-page-list";

export default {
  mixins: [AppComponentBase],
  name: "TaskIndex",
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      loading: false,
      windowHeight: 0,
      ProjectData: [],
      statusList: {
        Status: ["Process"],
      },
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      searchFormInline: {
        projectId: "",
        projectName: "",
        SkipCount: 0,
        MaxResultCount: 30,
        Sorting: "",
        filterText: "",
        name: "",
        phoneNum: "",
        taskStatus: undefined,
        idCard: "",
        taskId: undefined,
        taskName: "",
      },
      tableData: [],
      columns: [
        {
          title: this.l("姓名"),
          dataIndex: "realName",
          align: "center",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: this.l("性别"),
          dataIndex: "sex",
          align: "center",
          scopedSlots: { customRender: "sex" },
        },
        // {
        //   title: this.l("年龄"),
        //   dataIndex: "age",
        //   //   sorter: true,
        //   width: 80,
        //   align: "center",
        //   scopedSlots: { customRender: "age" },
        // },
        {
          title: this.l("手机号码"),
          dataIndex: "phoneNumber",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: this.l("身份证号"),
          dataIndex: "idCard",
          sorter: false,
          width: 180,
          align: "center",
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: this.l("所属任务"),
          dataIndex: "taskName",
          sorter: false,
          ellipsis: true,
          width: 250,
          align: "center",
          scopedSlots: { customRender: "taskName" },
        },
        {
          title: this.l("任务编码"),
          dataIndex: "taskCode",
          align: "center",
          width: 250,
          scopedSlots: { customRender: "taskCode" },
        },
        {
          title: this.l("报名日期"),
          dataIndex: "creationTime",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "creationTime" },
        },
        {
          title: this.l("状态"),
          dataIndex: "taskStatus",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "taskStatus" },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          width: 120,
          scopedSlots: { customRender: "actions" },
        },
      ],
      commonService: null,
      auditStatus: ["未审核", "待签约", "任务进行中", "任务已经完结", "拒绝", "已终止"],
      taskStatusList: [
        // { value: "", label: "全部" },
        {
          value: 0,
          label: "未审核",
        },
        {
          value: 1,
          label: "待签约",
        },
        {
          value: 2,
          label: "任务进行中",
        },
        {
          value: 3,
          label: "任务已经完结",
        },
        {
          value: 4,
          label: "拒绝",
        },
             {
          value: 5,
          label: "已终止",
        },
      ],
      selectedRowKeys: [],
      selectedRows: [],
      form: this.$form.createForm(this, { name: "coordinated" }),
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "审核",
        cancelText: "取消",
        okText: "确认",
        form: {},
        id: null,
        disabled: false,
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
      taskList: [
        {
          id: null,
          name: "全部",
        },
      ],
    };
  },
  watch: {
    openKeys(val) {
      console.log("openKeys", val);
    },
  },
  methods: {
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          //   selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
                 searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
          this.searchFormInline.taskId = undefined;
          this.searchFormInline.projectId = data.id;
          this.searchFormInline.projectName = data.name;
          this.getData();
          this.getTaskByProjectId(0, data.id);
        }
      });
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "project-user",
        isShow: true,
        fns: {
          isShow: true,
          detailList: [
             {
              granted: this.isGranted("Pages.LGModul.Signup.Apply"),
              name: this.l("审核"),
              icon: "profile",
              showFunc(record) {
                return record.taskStatus == "未审核"
              },
              fn: (data) => {
                _this._audit([data.personnelId], data.taskId);
              },
            },
          ],
          // dropList: [
            // {
            //   granted: this.isGranted("Pages.LGModul.Signup.Apply"),
            //   name: this.l("审核"),
            //   icon: "profile",
            //   fn: (data) => {
            //     // console.log(data);
            //     if (data.taskStatus == "未审核") {
            //       _this._audit([data.personnelId], data.taskId);
            //     } else {
            //       // _this._audit([data.personnelId]);
            //       _this.message.warn("已经审核过了！");
            //     }
            //     // _this._taskDetail(data.id);
            //   },
            // },
          // ],
        },
      };
      this.actionsType = obj;
    },
    handleClick(e) {
      this.getTaskList(e.key);
    },
    TaskDown() {
      console.info("down");
    },
    titleClick(index, id) {
      // console.log(e);
      // console.log("titleClick", e);
      this.getTaskByProjectId(index, id);
    },
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.searchFormInline.Sorting = order ? `${columnKey} ${order}` : "";
      // console.log(newV);
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onChange(page, pageSize) {
      pageSize = pageSize ? pageSize : this.searchFormInline.MaxResultCount;
      this.pageNumber = page;
      this.searchFormInline.SkipCount = (page - 1) * pageSize;
      this.searchFormInline.MaxResultCount = pageSize;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.searchFormInline.SkipCount = 0;
      this.searchFormInline.MaxResultCount = size;
      this.getData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    refreshGoFirstPage() {
      this.searchFormInline.name = "";
      this.searchFormInline.projectName="";
      this.searchFormInline.projectId = undefined;
      this.searchFormInline.taskStatus = undefined;
      this.searchFormInline.phoneNum = "";
      this.searchFormInline.idCard = "";
      this.searchFormInline.filterText = "";
      this.searchFormInline.taskId=undefined;
      this.taskList = [];
      this.pageNumber = 1;
      this.searchFormInline.SkipCount = 0;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    getData() {
      this.getTaskList(this.searchFormInline.taskId);
    },
    createOrEdit(id) {
      ModalHelper.create(
        TaskForm,
        { id: id },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    _taskDetail() {
      ModalHelper.create(
        DetailForm,
        { id: "" },
        {
          isChange: true,
          width: "1400px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    async getProjectList() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/PersonnelTask/GetProjectList",
          params: {},
        });
        // console.log(res);
        let data = res.map((item) => {
          return {
            id: item.id,
            name: item.projectName,
            tasks: [],
          };
        });
        this.ProjectData.push(...data);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async getTaskByProjectId(i, projectId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/PersonnelTask/GetTaskByProjectId",
          params: {
            projectId: projectId,
          },
        });
        // console.log(res);
        if (res.length > 0) {
          let data = res.map((item) => {
            return {
              id: item.taskId,
              name: item.taskName,
            };
          });
          this.ProjectData[i].tasks = [];
          this.ProjectData[i].tasks.push(...data);
          this.taskList = [];
          this.taskList.push(...data);
        }else{
            
            this.ProjectData[i].tasks = [];
            this.taskList = [];
            this.searchFormInline.taskId=undefined;
        }
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async getTaskList(taskId) {
      this.searchFormInline.taskId = taskId;
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      })
      let that = this;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/PersonnelTask/GetPagedByTaskId",
          params: {
            projectId:this.searchFormInline.projectId,
            taskId: this.searchFormInline.taskId,
            name: this.searchFormInline.name,
            enumPersonnelTaskStatus: this.searchFormInline.taskStatus,
            phoneNum: this.searchFormInline.phoneNum,
            idCard: this.searchFormInline.idCard,
            filterText: this.searchFormInline.filterText,
            sorting: this.searchFormInline.Sorting,
            maxResultCount: this.searchFormInline.MaxResultCount,
            skipCount: this.searchFormInline.SkipCount,
          },
        });

        this.tableData = res.items.map((item) => {
          return {
            ...item,
            creationTime: item.creationTime
              ? moment(item.creationTime).format("YYYY-MM-DD")
              : "-",
            taskStatus: that.auditStatus[item.taskStatus],
          };
        });

        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.searchFormInline.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.searchFormInline.MaxResultCount + 1,
          this.pageNumber * this.searchFormInline.MaxResultCount,
        ];
        this.spinning = false;
      } catch (err) {
        console.error(err.message);
        this.spinning = false;
      } finally {
        // this.loading = false;
      }
    },
    batchPass() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "通过"));
        return;
      }

      let personnelIds = [];
      let taskId = "";
      for (let i = 0; i < this.selectedRows.length; i++) {
        let item = this.selectedRows[i];
        personnelIds.push(item.personnelId);
        if (taskId == "") {
          taskId = item.taskId;
        } else if (taskId != item.taskId) {
          abp.message.warn("只能对同一个任务下的人员进行批量通过");
          return false;
        }
      }
      this.pass(personnelIds, taskId);
    },
    async pass(personnelIds, taskId) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/PersonnelTask/BatchPass",
          params: {
            taskId: taskId,
            personnelIds: personnelIds,
          },
        });
        this.$notification["success"]({
          message: res,
        });
        this.getData();
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async refuse(personnelIds, taskId) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/PersonnelTask/BatchRefuse",
          params: {
            taskId: taskId,
            personnelIds: personnelIds,
          },
        });
        this.$notification["success"]({
          message: res,
        });
        this.getData();
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    batchRefuse() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "拒绝"));
        return;
      }
      let personnelIds = [];
      let taskId = "";
      for (let i = 0; i < this.selectedRows.length; i++) {
        let item = this.selectedRows[i];
        personnelIds.push(item.personnelId);
        if (taskId == "") {
          taskId = item.taskId;
        } else if (taskId != item.taskId) {
          abp.message.warn("只能对同一个任务下的人员进行批量拒绝");
          return false;
        }
      }

      this.refuse(personnelIds, taskId);
    },
    _audit(idParams, taskId) {
      let _this = this;
      this.inputModalParams.title = "审核";
      this.inputModalParams.confirm = function () {
        _this.form.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.inputModalParams.confirmLoading = true;
            try {
              if (values.isPass) {
                _this.pass(idParams, taskId);
              } else {
                _this.refuse(idParams, taskId);
              }
              _this.inputModalParams.confirmLoading = false;
              _this.inputModalParams.visible = false;
            } catch (error) {
              _this.inputModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.inputModalParams.visible = true;
    },
    handleChange(value) {
      this.inputModalParams.disabled = value ? true : false;
    },
    async getToExcelFileTes() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/PersonnelTask/GetToExcelFileTes",
          params: {
            taskId: this.searchFormInline.taskId,
            name: this.searchFormInline.name,
            enumPersonnelTaskStatus: this.searchFormInline.taskStatus,
            phoneNum: this.searchFormInline.phoneNum,
            idCard: this.searchFormInline.idCard,
            filterText: this.searchFormInline.filterText,
            sorting: this.searchFormInline.Sorting,
            maxResultCount: this.searchFormInline.MaxResultCount,
            skipCount: this.searchFormInline.SkipCount,
          },
        });
        this.spinning = false;
        return res;
      } catch (error) {
        console.error(error);
        this.spinning = false;
      }
      return null;
    },
    exportToExcel() {
      if (this.totalItems <= 0) {
        this.$message.warning(this.l("没有导出的数据"));
        return false;
      }
      debugger;
      var result = this.getToExcelFileTes();
      result.then((res) => {
        if (res == null) {
          abp.message.warn("获取数据失败");
          return false;
        }

        fileDownloadService.downloadTempFile(res);
      });
    },
  },
  created() {
    if (this.$route.query && this.$route.query.status) {
      this.searchFormInline.taskStatus = Number(this.$route.query.status)
    }
    this.windowHeight = window.innerHeight - 205;
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);

    this.initActionsType();
    this.getProjectList();
    // this.getTaskByProjectId("b46c72c9-b6b8-4693-a9a3-d89481ac7abf");
    this.getData();
  },
};
</script>
<style>
.tk_container {
  width: 98%;
  margin: 0 auto;
  /* background-color: #ccc; */
}

.tk_common {
  padding: 0 5px 0 5px;
  background-color: white;
}

.tk_border {
  /* border: 1px solid #ccc; */
}

.tk_body {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.tk_left_top {
  margin: 7px 3px 0px 5px;
  padding-left: 5px;
}

.tk_commonline {
  height: 32px;
  line-height: 32px;
}

.tk_commonline > i {
  /* color: #52c41a; */
  font-size: 18px;
}

.tk_commonline > strong {
  font-size: 16px;
}

.tk_left_item {
  margin: 0px 3px 10px 5px;
  padding-top: 0;
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
  overflow-y: scroll;
}

.tk_left_menu {
  border: 0 !important;
}

.tk_list {
  margin: 7px 3px 0px 5px;
}
</style>
